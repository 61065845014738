.modal.barcode-scan {
    background: #fff;
    padding: var(--spacing-2x);
}

.barcode-scan__header, .barcode-scan__text {
    font: var(--m);
    margin: 0;
    padding-right: var(--spacing-4x);
}

.barcode-scan__header {
    font-weight: bold;
}

.barcode-scan__text {
    font-weight: lighter;
    margin-bottom: var(--spacing-unit);
}

.barcode-scan .modal__close-button {
    z-index: 10;
    color: var(--text);
}

.barcode-scan__video-wrap {
    position: relative;
    padding-top: 90%;
    background: #000;
    flex-shrink: 0;
    margin-bottom: var(--spacing-unit);
}

.barcode-scan__video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
}

.barcode-scan__croparea {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
