.barcode-form {
    align-items: center;
    border-bottom: 2px solid;
    display: flex;
    font: var(--xs);
    margin: 0 auto;
    padding-bottom: .4rem;
    width: fit-content;
}

.barcode-form input {
    appearance: none;
    background: transparent;
    color: inherit;
    border: 0;
    width: 185px;
}

.barcode-form input::-webkit-input-placeholder {
    opacity: 1;
}

.barcode-form .icon {
    height: 14px;
    margin-left: var(--spacing-unit);
    width: 14px;
}
