@import '~@/components/barcode-form/barcode-form.css';
@import '~@/components/barcode-scan-modal/barcode-scan-modal.css';
@import '~@/components/core-styling/core-styling.css';
@import '~@/components/error-overlay/error-overlay.css';
@import '~@/components/horizontal-scroll/horizontal-scroll.css';
@import '~@/components/icon/icon.css';
@import '~@/components/modal/modal.css';
@import '~@/components/browse-happy/browse-happy.css';

@media (web) {
    .homepage {
        padding: 0;
    }

    .homepage-header__icon {
        width: 6.812rem;
        height: 6.812rem;
        margin-bottom: .5rem;
    }

    .homepage-header {
        background-position: center;
        background-size: cover;
        color: #ffffff;
        position: relative;
        text-align: center;
    }

    .homepage-header__error {
        display: none;
    }

    .homepage-header__error > .icon {
        width: 6rem;
        margin-bottom: var(--spacing-unit);
    }

    .homepage-header__error-header, .homepage-header__error-text {
        font: var(--l);
        margin: 0;
    }

    .homepage-header__error-header {
        font-weight: bold;
    }

    .homepage-header__error-text {
        font: var(--l);
        margin-bottom: var(--spacing-2x);
    }

    body[data-status="no-camera"] .homepage-header,
    body[data-status="wrong-code"] .homepage-header {
        color: var(--text);
    }

    body[data-status="no-camera"] .homepage-header__no-camera,
    body[data-status="wrong-code"] .homepage-header__wrong-code {
        display: block;
    }

    body[data-status="no-camera"] .homepage-header::before,
    body[data-status="wrong-code"] .homepage-header::before {
        background: #ffffff;
        filter: none;
    }

    body[data-status="no-camera"] .homepage-header__scan-button,
    body[data-status="wrong-code"] .homepage-header__scan-button {
        background-color: #333333;
    }

    body[data-status] .homepage-header__start {
        display: none;
    }

    .homepage-header__start h1 {
        font: var(--xxl);
        font-weight: bold;
        margin-bottom: 10rem;
    }

    .homepage-header__scan-button {
        backdrop-filter: blur(15px);
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 100px;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        margin-bottom: var(--spacing-2x);
        padding: 0 var(--spacing-unit);
        text-align: center;
        text-decoration: none;
    }

    .homepage-header__scan-button .icon {
        height: 3.2rem;
        width: 3.15rem;
        display: inline-block;
    }

    .homepage__block {
        text-align: center;
        padding: var(--spacing-2x);
    }

    .homepage-why {
        background: var(--tertiary-lg);
        color: #ffffff;
        padding-top: var(--spacing-3x);
    }

    .homepage-why .icon {
        height: 80px;
        width: 80px;
    }

    .homepage-why__bullet-list {
        list-style: none;
        padding: 0;
        margin: var(--spacing-2x) 0 0;
    }

    .homepage-why__bullet-list li {
        font: var(--l);
        font-weight: bold;
        margin-bottom: var(--spacing-5x);
        opacity: 0;
        transform-origin: bottom;
        transform: translateY(25px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .homepage-why__bullet-list li.visible {
        opacity: 1;
        transform: translateY(0);
    }

    .homepage-why__bullet-list li:last-of-type {
        margin-bottom: var(--spacing-2x);
    }

    .homepage-usps {
        padding: 0 12px 0;
    }

    .homepage-usp {
        width: 100%;
        max-width: 60rem;
        margin: 0 auto 10px;
        border-radius: 25px;
        color: #ffffff;
    }

    .homepage-usp:first-of-type {
        margin-top: 100px;
    }

    .homepage-usp:last-of-type {
        margin-bottom: 100px;
    }

    .homepage-usp:nth-of-type(3n+1) {
        background: var(--tertiary-lg);
    }

    .homepage-usp:nth-of-type(3n+2) {
        background: var(--error-lg);
    }

    .homepage-usp:nth-of-type(3n+0) {
        background: var(--primary-lg);
    }

    .homepage-usp .container > .icon {
        width: 2.5rem;
        margin-bottom: var(--spacing-1x);
    }

    .homepage-usp h2, .homepage-usp p {
        font: var(--xxl);
        line-height: 1.15;
        margin: 0;
    }

    .homepage-usp p {
        font-weight: lighter;
        margin-bottom: var(--spacing-2x);
    }

    .homepage-usp h2 {
        font-weight: bold;
    }

    .homepage-usp__button {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        font: inherit;
        text-decoration: none;
        font-weight: bold;
        color: inherit;
    }

    .homepage-usp__button > .icon {
        width: 1.875rem;
        height: 1.875rem;
        margin-right: .5rem;
    }

    .homepage-usp__modal {
        color: var(--text);
        height: calc(100% - 2rem);
        margin: 1rem;
        padding: 2rem;
        text-align: left;
        width: calc(100% - 2rem);
    }

    .homepage-usp__modal h2,
    .homepage-usp__modal p:first-of-type {
        display: inline;
        font: var(--xl);
        text-align: left;
    }

    .homepage-usp__modal p:first-of-type {
        margin-left: 4px;
    }

    .homepage-usp__modal h2 {
        font-weight: bold;
    }

    .homepage-usp__modal p {
        font: var(--m);
        text-align: left;
    }

    .homepage-usp__modal p:last-of-type {
        margin-bottom: 0;
    }

    .homepage-usp__modal p:nth-of-type(2) {
        margin-top: 2rem;
    }

    .homepage-usp__modal .icon {
        color: black;
    }

    .homepage-usp__modal .container .icon {
        display: block;
        height: 3.125rem;
        margin: 0 auto 2rem;
        width: 3.125rem;
    }

    .homepage__footer {
        background: var(--shade);
        color: #5a5a5a;
        padding: var(--spacing-2x);
        text-align: center;
    }

    .homepage__footer h2 {
        font: var(--l);
        font-weight: bold;
        margin: 0;
    }

    .homepage__footer h3 {
        margin: 0;
        font-weight: normal;
        font: var(--l);
        margin-bottom: var(--spacing-1x);
    }

    .homepage__footer a {
        color: var(--tertiary);
        display: block;
        font: var(--l);
        margin-bottom: var(--spacing-2x);
    }

    .homepage__footer-nav a {
        color: inherit;
        display: inline;
        font: var(--xs);
        text-decoration: none;
    }

    .homepage__footer-nav a:after {
        display: inline-block;
        margin-left: .5rem;
        margin-right: .5rem;
        content: '|';
    }

    .homepage__footer-nav a:last-child:after {
        content: none;
    }

    .homepage__copyright {
        font: var(--xxs);
        margin-top: var(--spacing-2x);
    }

    .homepage__copyright p {
        line-height: 1;
        margin: 0;
    }

    .homepage__copyright p:first-of-type {
        margin-bottom: 8px;
    }
}

@media (kiosk) {
    .homepage {
      align-items: center;
      background-color: var(--tertiary);
      color: white;
      display: flex;
      fill: white;
      flex-direction: column;
      min-height: 100vh;
      justify-content: space-between;
      padding: 5%;
      text-align: center;
      width: 100%;
    }

    .homepage > .icon:first-child {
      height: 15rem;
      width: 15rem;
    }

    .homepage__hgroup h1,
    .homepage__hgroup h2 {
        font: var(--xxl);
        font-weight: bold;
        margin: 0;
    }

    .homepage__hgroup h2 {
      font-weight: normal;
    }

    .homepage__hashtag {
      font-weight: bold;
      font: var(--xl);
      margin-bottom: var(--spacing-1x);
    }

    .homepage__footer .icon {
      height: 9.5rem;
      position: relative;
      width: 9.5rem;
    }

    .homepage__footer .icon::after {
      animation: scan 3s infinite ease-in-out;
      background-color: var(--error);
      content: '';
      height: 3.75rem;
      left: 1.95rem;
      position: absolute;
      top: 2.90rem;
      width: 5px;
      z-index: var(--layer-raised);
    }

    @keyframes scan {
      0%, 100% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(5.325rem);
      }
    }

    .homepage__nojavascript-error {
        background-color: var(--error);
        left: 0;
        margin: 0;
        padding: 0.875em 1em;
        position: fixed;
        text-align: center;
        top: 0;
        width: 100%;
    }

}
