.horizontalSection {
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    width: 100%;
    background-color: var(--shade);
}

.tallOuterContainer {
    height: 0;
    position: relative;
    width: 100%;
}

.stickyInnerContainer {
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    position: sticky;
    top: 0;
    width: 100%;
}

.horizontalTranslateContainer {
    height: 100%;
    position: absolute;
    transform: translateX(0);
    will-change: transform;
    width: 100%;
}

.childContainer {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding-left: var(--spacing-3x);
    position: relative;
}

.child {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    justify-content: space-evenly;
    margin-right: var(--spacing-6x);
    overflow: hidden;
    position: relative;
    text-align: center;
    max-width: 450px;
    width: calc(100vw - var(--spacing-6x));
}

.child p {
    font: var(--xl);
    font-size: 26px;
    font-weight: bold;
    height: 95px;
    margin: 0;
}

.child img {
    max-height: 60vh;
    max-width: 100%;
}

.child:last-of-type {
    margin-right: 0;
}

@media (min-width: 920px) {
    .horizontalSection,
    .stickyInnerContainer {
        height: auto;
        min-height: auto;
    }

    .tallOuterContainer {
        height: 100%;
        margin: 0 auto;
        max-width: 70rem;
        width: 95%;
    }

    .stickyInnerContainer,
    .horizontalTranslateContainer {
        position: initial;
    }

    .childContainer {
        align-items: center;
        justify-content: center;
        padding: var(--spacing-3x) 0;
    }

    .child {
        flex: 1 1;
        justify-content: space-between;
        max-width: 350px;
        overflow: visible;
        width: 33.333%;
    }

    .child p {
        margin-bottom: var(--spacing-3x);
    }

    .child img {
        max-height: 350px;
    }

    .child:nth-of-type(2) img {
        max-height: 400px;
    }
}
